/** @jsx jsx */
import { jsx, Container, Box, Flex, Button, Grid, Themed } from "theme-ui";
import { graphql } from "gatsby";
import { useState, useContext } from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import RichText2 from "../components/RichText2";
import PageTitle from "../components/PageTitle";
import BreadCrumbs from "../components/BreadCrumbs";
import ContentTopicLinks from "../components/ContentTopicLinks";
import RelatedContent from "../components/RelatedContent";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import { GatsbyImage } from "gatsby-plugin-image";
import { createImageUrl } from "../utils/utils";
import { m } from "framer-motion";

const ContentPageTemplate = ({ data, pageContext }) => {
  const {
    pageType,
    title,
    navigationTitle,
    slug,
    parentPage,
    metaTitle,
    metaKeywords,
    metaDescription,
    hideTitle,
    contentArea,
    headerImage,
    hideBreadcrumb,
    hideHeader,
    hidden,
    noIndex,
    noSnippet,
    enableAbTesting,
    showNpsPopup,
    canonicalTag,
    contentTopics,
  } = data.contentfulPage;

  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
    author,
    social,
    organization,
  } = useSiteMetadata();

  const { node_locale: locale, language } = pageContext;

  const metadataTitle = metaTitle ? metaTitle : title;

  const metadataDescription =
    metaDescription &&
    metaDescription.internal &&
    metaDescription.internal.content
      ? metaDescription.internal.content
      : defaultDescription;

  const metadataKeywords = metaKeywords ? metaKeywords : [];

  const metaImage =
    (headerImage &&
      headerImage.gatsbyImageData &&
      createImageUrl(headerImage)) ||
    siteUrl + defaultImage;

  return (
    <Layout
      pageContext={pageContext}
      pageType={pageType}
      enableAbTesting={enableAbTesting}
      showNpsPopup={showNpsPopup}
    >
      <SEO
        lang={language}
        title={metadataTitle}
        description={metadataDescription}
        keywords={metadataKeywords}
        image={metaImage}
        paths={pageContext.paths}
        hidden={hidden}
        noIndex={noIndex}
        noSnippet={noSnippet}
        canonicalTag={canonicalTag}
      />
      {!hideBreadcrumb && (
        <Container>
          <BreadCrumbs
            slug={slug}
            navigationTitle={navigationTitle}
            parentPage={parentPage}
            language={language}
          />
        </Container>
      )}
      <Container variant="narrow" id="content">
        {headerImage && !hideHeader && (
          <GatsbyImage image={headerImage.gatsbyImageData} />
        )}

        {!hideTitle && <PageTitle>{title}</PageTitle>}
        {contentArea && contentArea.raw && (
          <RichText2
            content={contentArea}
            locale={locale}
            language={language}
          />
        )}
      </Container>
      {contentTopics && (
        <Container>
          <RelatedContent
            contentTopics={contentTopics}
            locale={locale}
            currentPageSlug={slug}
            pageType={pageType}
          />
          <ContentTopicLinks contentTopics={contentTopics} />
        </Container>
      )}
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    contentfulPage(id: { eq: $id }) {
      id
      contentful_id
      __typename
      pageType
      title
      metaTitle
      navigationTitle
      slug
      parentPage {
        ... on ContentfulProductPage {
          slug
          navigationTitle
        }
        ... on ContentfulPage {
          slug
          navigationTitle
        }
        ... on ContentfulThemePage {
          slug
          navigationTitle
        }
      }
      canonicalTag
      metaKeywords
      metaDescription {
        internal {
          content
        }
      }
      contentArea {
        raw
        references {
          ...fragmentContentfulFrontpage
          ...fragmentContentfulThemePage
          ...fragmentContentfulProductPage
          ...fragmentContentfulPage
          ...fragmentContentfulContentHubPage
          ...fragmentContentfulCallToAction
          ...fragmentContentfulComponent
          ...fragmentContentfulLiftUpLinks
          ...fragmentContentfulLiftUpEasyStep
          ...fragmentContentfulLiftUpBox
          ...fragmentContentfulAsset
          ...fragmentContentfulResponsiveIframe
          ...fragmentContentfulAccordion
          ...fragmentContentfulGrid
          ...fragmentContentfulInlineImage
          ...fragmentContentfulContact
          ...fragmentContentfulContentHubLiftUps
          ...fragmentContentfulEmbeddedNotification
          ...fragmentContentfulTwoColumns
          ...fragmentContentfulLiftUpColor
          ... on Node {
            ...fragmentContentfulDynamicSection
          }
          ... on Node {
            ...fragmentContentfulProcessGuide
          }
          ... on Node {
            ...fragmentContentfulContentTopicSelector
          }
          ... on Node {
            ... on ContentfulInsuranceBrochure {
              id
              contentful_id
              __typename
              slug
            }
          }
          ... on Node {
            ... on ContentfulInsuranceTermsPage {
              id
              contentful_id
              __typename
              slug
            }
          }
        }
      }
      hideTitle
      headerImage {
        gatsbyImageData(aspectRatio: 3, quality: 65, width: 2000)
      }
      hideHeader
      hideBreadcrumb
      hidden
      noIndex
      noSnippet
      enableAbTesting
      showNpsPopup
      contentTopics {
        ...fragmentContentfulContentTopic
      }
    }
  }
`;
export default ContentPageTemplate;
