/** @jsx jsx */
import { jsx, Button, Flex } from "theme-ui";
import Link from "../components/Link";
import { getUrlFromSlug } from "../utils/utils";
import { useSitePages } from "../hooks/useSitePages";

const ContentTopicLinks = ({ contentTopics }) => {
  const nodes = useSitePages();

  return (
    <Flex
      sx={{
        gap: 2,
        mt: 2,
        mb: 4,
        flexDirection: ["row", "row"],
        flexWrap: "wrap",
      }}
    >
      {contentTopics.map((topic) => {
        const getLinkSlug = (topic) => {
          if (topic.customLink) {
            const customLinkPath = getUrlFromSlug(topic.customLink.slug, nodes);
            return customLinkPath;
          } else {
            const topicParentPagePath = getUrlFromSlug(
              topic.parentPage.slug,
              nodes
            );
            return `${topicParentPagePath}/${topic.slug}`;
          }
        };

        const pageSlug = getLinkSlug(topic);

        return (
          <Link target={pageSlug} key={pageSlug}>
            <Button variant="secondary" sx={{ flexGrow: 0 }}>
              {topic.title}
            </Button>
          </Link>
        );
      })}
    </Flex>
  );
};

export default ContentTopicLinks;
