import { useState, useContext } from "react";
import { jsx, Box, Grid, Themed } from "theme-ui";
import { useAllContentByContentTopics } from "../hooks/useAllContentByContentTopics";
import { LocalizationContext } from "../context/LocalizationContext";
import ContentTopicItem from "../components/ContentTopicItem";

const RelatedContent = ({
  contentTopics,
  locale,
  currentPageSlug,
  pageType,
}) => {
  const { translate } = useContext(LocalizationContext);

  const topicCount = 3;

  const [matchingContentPages, setMatchingContentPages] = useState(
    contentTopics &&
      useAllContentByContentTopics(
        contentTopics.slice(0, topicCount),
        locale,
        topicCount
      )
  );

  const displayPages = matchingContentPages?.matchingContent;
  const displayPagesFilterCurrent = displayPages?.filter((item) => {
    return item.slug !== currentPageSlug;
  });

  const displayPagesFilterPageType = displayPagesFilterCurrent?.filter(
    (item) => {
      return item.pageType === pageType;
    }
  );

  return (
    <Box>
      {displayPagesFilterPageType.length > 0 && (
        <Box
          sx={{
            my: 4,
          }}
        >
          <Themed.h2>{translate("contentTopic.relatedContent")}</Themed.h2>
          <Grid
            aria-live="polite"
            sx={{
              gridTemplateColumns: ["1fr", "repeat(3, 1fr)"],
              mb: 3,
            }}
          >
            {displayPagesFilterPageType?.slice(0, 3).map((item, index) => {
              return (
                <ContentTopicItem
                  item={item}
                  index={index}
                  key={item.contentful_id}
                  translate={translate}
                />
              );
            })}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default RelatedContent;
